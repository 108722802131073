import { Component, Renderer2 } from '@angular/core';
import { FIREBASE_CONFIG } from 'src/environments/environment';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics'
import 'firebase/database';

import { Title } from '@angular/platform-browser';
import { AltomateService } from './altomate.service';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  // Ching Hong Start


  constructor(private titleService: Title, private renderer: Renderer2, private altomateService: AltomateService) {
    
    const host = window.location.host;
    console.log(host);
    firebase.initializeApp(FIREBASE_CONFIG);
    firebase.database().ref('settings/bitrixUrl').once('value', setts => {
      let settingArray = Object.values(setts.val() || {})
      let settings = settingArray.find(g => g['subdomain'] === host)
      this.changeWebsiteMetadata(settings?.['title'] || '', settings?.['logo'] || '');
      this.altomateService.bitrixUrl = (settings?.['bitrixUrl'] || '').toString()
      this.altomateService.reponsible_person = (settings?.['reponsible'] || '').toString()
      this.altomateService.isLoading = false
      this.altomateService.company_logo = settings?.['logo'] || ''
      console.log('Setting: ', settings);
    })
    
  }

  changeWebsiteMetadata(title, logo) {
    this.titleService.setTitle(title)
    let link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || this.renderer.createElement('link');
    link.type = 'image/png';
    link.rel = 'icon';
    link.href = logo;
    this.renderer.appendChild(document.head, link);
  }

  // Ching Hong End
}

/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/* eslint-disable eol-last */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { TokenService } from '../services/token.service';
import { AltomateService } from '../altomate.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingToken = false;

    constructor(private tokenService: TokenService, private toastCtrl: ToastController, private altomateService: AltomateService) { }
    // Intercept every HTTP call
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addToken(request)).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 400:
                            return this.handle400Error(err);
                        case 401:
                            return this.handle401Error(request, next);
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            })
        );
    }

    // Add our current access token from the service if present
    private addToken(req: HttpRequest<any>) {
        // console.log('lol')
        // console.log('hi: ', this.altomateService.reponsible_person);
        // console.log(this.altomateService.bitrixUrl);
        return req.clone({
            headers: new HttpHeaders({

                // Ching Hong Start

                'reponsible_person': this.altomateService.reponsible_person,
                'bitrix_url': this.altomateService.bitrixUrl

                // Ching Hong End
            })
        });

    }

    private async handle400Error(err) {
        return of(null);
    }

    // Indicates our access token is invalid, try to load a new one
    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Check if another call is already using the refresh logic
        return of(null);
    }

}
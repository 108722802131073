import { HttpClient } from '@angular/common/http';
import { Injectable, ViewChild } from '@angular/core';
import { NavController, Platform, ToastController } from '@ionic/angular';
import { isValidPhoneNumber } from 'libphonenumber-js';
import * as lodash from 'lodash';
import { SafariViewController } from '@awesome-cordova-plugins/safari-view-controller/ngx';
import firebase from 'firebase/app';
import 'firebase/database';
import { DataService } from './data.service';
import { baseUrl } from 'src/environments/environment';
declare var Swal;
import { SafeArea } from 'capacitor-plugin-safe-area';

@Injectable({
  providedIn: 'root'
})
export class AltomateService {

  constructor(
    private http: HttpClient,
    private toastController: ToastController,
    private safariViewCtrl: SafariViewController,
    private platform: Platform,
    private dataService: DataService,
    private nav: NavController
  ) {

  }

  firstInitial = true
  firstInitial2 = true
  xeroDetails = {}

  // Ching Hong Start

  public bitrixUrl = ''
  public reponsible_person = ''
  public isLoading = true

  public company_logo = ''
  

  // Ching Hong End

  phoneValidater(phone) {
    return isValidPhoneNumber(phone)
  }


  widther() {
    return this.platform.width() >= 768 ? 'desktop' : 'mobile'
  }

  emailValidator(email) {
    if (email) {
      var re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    } else {
      return false;
    }
  }

  getCurrencyList() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/currency.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getPhoneNumbers() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/phone.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getNatureOfBusiness() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/nature_of_business.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getSgSSIC() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/ssic_sg.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getFinancialYear() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/financial_year_end.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getSourceOfFund() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/sources_of_funds.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getCorporateSourceOfFund() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/corporate_source_of_funds.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  

  getBanks() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/bank.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getCountryState() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/malaysia_states.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getRaces() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/races.json').subscribe((res) => {
        resolve(res)
      })
    })
  }

  getPepTypes() {
    return new Promise((resolve, reject) => {
      this.http.get('assets/data/pep_types.json').subscribe((res) => {
        resolve(res)
      })
    })
  }


  fileChange(event) {
    return new Promise((resolve, reject) => {
      if (event.target.files && event.target.files[0] && event.target.files[0].size < (10485768)) {

        const file = event.target.files[0];
        let extension = event.target.files[0].type
        if (extension == 'application/pdf') {

          const reader = new FileReader();
          reader.onloadend = () => {
            const base64String = reader.result.toString().replace(';base64,', "thisisathingtoreplace;")
            let imgarr = base64String.split("thisisathingtoreplace;")
            event.target.value = ''

            resolve({ success: true, data: { image: reader.result.toString(), files: file, base64String: imgarr[1] } })
          };

          reader.readAsDataURL(file);

        } else {
          var canvas = <HTMLCanvasElement>document.createElement("canvas");
          var ctx = canvas.getContext("2d");
          var maxW = 400;
          var maxH = 400;
          var img = new Image;
          img.onload = () => {
            var iw = img.width;
            var ih = img.height;
            var scale = Math.min((maxW / iw), (maxH / ih));
            var iwScaled = iw * scale;
            var ihScaled = ih * scale;
            canvas.width = iwScaled;
            canvas.height = ihScaled;
            ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
            event.target.value = ''


            let imagec = canvas.toDataURL();
            let imgggg = imagec.replace(';base64,', "thisisathingtoreplace;")
            let imgarr = imgggg.split("thisisathingtoreplace;")
            let base64String = imgarr[1]

            resolve({ success: true, data: { image: imagec, files: file, base64String: base64String } })
          };

          img.src = URL.createObjectURL(file);
        }



        // var canvas = <HTMLCanvasElement>document.createElement("canvas");
        // var ctx = canvas.getContext("2d");
        // var cw = canvas.width;
        // var ch = canvas.height;
        // var maxW = 400;
        // var maxH = 400;
        // var type = event.target.files[0].type;
        // var file = event.target.files[0]
        // var img = new Image;
        // img.onload = () => {
        //   var iw = img.width;
        //   var ih = img.height;
        //   var scale = Math.min((maxW / iw), (maxH / ih));
        //   var iwScaled = iw * scale;
        //   var ihScaled = ih * scale;
        //   canvas.width = iwScaled;
        //   canvas.height = ihScaled;
        //   ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        //   event.target.value = ''


        //   let imagec = canvas.toDataURL();
        //   let imgggg = imagec.replace(';base64,', "thisisathingtoreplace;")
        //   let imgarr = imgggg.split("thisisathingtoreplace;")
        //   let base64String = imgarr[1]

        //   resolve({ success: true, data: { image: imagec, files: file, base64String: base64String } })
        // }

        // img.src = URL.createObjectURL(event.target.files[0]);

      } else {
        reject({ success: false, message: '"Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)"' })
        alert("Your Current Image Too Large, " + event.target.files[0].size / (10241024) + "MB! (Please choose file lesser than 8MB)")
      }
    })
  }


  pictureToLink(folder, file, extraParametes) {


    return new Promise((resolve, reject) => {
      if (file != null && file != undefined && file != '') {


        let formData = new FormData();
        formData.append('file', file, file.name);

        const randomKey = Date.now().toString(36).substring(0, 5) + Math.random().toString(36).substr(2).substring(0, 4)

        formData.append("fileName", (randomKey + file.name));


        this.http.post(baseUrl + '/uploadFileToGS', formData, {}).subscribe((res) => {
          resolve({ success: true, link: res['Location'], extraParametes: extraParametes })
        })


      }else {
        const errorMessage = 'File is null, undefined, or empty';
        console.error(errorMessage);
        reject({ success: false, error: errorMessage });
        
      }

    })
  }

  _base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  lengthOf(x) {

    x = x || []
    x = Array.isArray(x) ? x : Object.values(x)

    return x.length
  }

  showMessage(title, description, icon) {
    Swal.fire({
      title: title,
      text: description,
      icon: icon,
      showCancelButton: false,
      timerProgressBar: false,
      timer: 5000,
    })
  }

  pleasewait(title, text) {
    Swal.fire({
      title: title,
      html: text,
      timerProgressBar: false,
      allowOutsideClick: false,
      confirmButtonText: false,
      //timer: 5000,

      didOpen: () => {
        Swal.showLoading()
      },
    })
  }

  showContent(title, element) {
    Swal.fire({
      title: title,
      html: element,
      timer: 10000,
    }).then(() => {
      this.nav.pop();
    })
  }

  swal_button(title, text, icon) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: title,
        html: text,
        icon: icon,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Yes",
        cancelButtonText: "No"
      }).then((result) => {
        if (result.isConfirmed) {
          resolve('Confirm')
        } else {
          resolve('No')
        }
      })
    })
  }

  swalclose() {
    Swal.close()
  }

  async presentToast(message, position, duration, çolor) {
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: position,
      color: çolor
    });

    toast.present();
  }

  isNullOrEmpty(...args: any[]) {
    let temp = args[0];

    if (temp === undefined || temp === null) {
      return true;
    }

    if (typeof temp === 'string') {
      if (temp === undefined || temp === null || temp.trim() === '') {
        return true;
      }

      return false;
    }

    if (typeof temp === 'object') {
      let variable = args[1];

      for (let i = 0; i < variable.length; i++) {
        if (
          (temp[variable[i]] === undefined ||
          temp[variable[i]] === null) &&
          (typeof temp[variable[i]] !== 'string')
        ) {
          return true;
        }

        if (
          temp[variable[i]].toString().trim() === '' &&
          (typeof temp[variable[i]] === 'string')
        ) {
          return true;
        }
      }

      return false;
    }

    return true
  }

  openUrl(url) {
    this.safariViewCtrl.isAvailable()
      .then(async (available: boolean) => {
        if (available) {
          this.safariViewCtrl.show({
            url: url,
          }).subscribe((result: any) => {
            if (result.event === 'opened') console.log('Opened');
            else if (result.event === 'loaded') console.log('Loaded');
            else if (result.event === 'closed') console.log('Closed');
          },
            (error: any) => console.error(error)
          );

        } else {
          window.open(url, '_system');
        }
      }).catch(async (error) => {
        window.open(url, '_system');
      });;
  }






}



/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable quote-props */
/* eslint-disable eol-last */
/* eslint-disable arrow-body-style */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/ban-types */
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, switchMap, filter, take } from 'rxjs/operators';
import { ToastController } from '@ionic/angular';
import { TokenService } from '../services/token.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    isRefreshingToken = false;

    constructor(private tokenService: TokenService, private toastCtrl: ToastController) {}
    // Intercept every HTTP call
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addToken(request)).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    switch (err.status) {
                        case 400:
                            return this.handle400Error(err);
                        case 401:
                            return this.handle401Error(request, next);
                        default:
                            return throwError(err);
                    }
                } else {
                    return throwError(err);
                }
            })
        );
    }

    // Add our current access token from the service if present
    private addToken(req: HttpRequest<any>) {
        console.log('lol')
        return req.clone({
            headers: new HttpHeaders({
                'REPONSIBLE_PERSON': '11646',
                'DEAL_COUNTRY': '5879',
                'DEAL_SOURCE': '6083',
                'SOURCE_CODE': 'UC_KYV8RM',
            })
        });
    }

    private async handle400Error(err) {
        return of(null);
    }

    // Indicates our access token is invalid, try to load a new one
    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // Check if another call is already using the refresh logic
        return of(null);
    }

}